.service {
	margin-bottom: 120px;
}
.service__list {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(2, 290px);
	grid-column-gap: 16px;
	grid-row-gap: 16px;
}
.service__item {
	position: relative;
}
.service__item_1 {
	grid-area: 1 / 1 / 2 / 3;
}
.service__item_2 {
	grid-area: 1 / 3 / 2 / 4;
}
.service__item_3 {
	grid-area: 1 / 4 / 2 / 5;
	& span {
		color: var(--accent);
	}
}
.service__item_4 {
	grid-area: 2 / 1 / 3 / 2;
	& span {
		color: var(--accent);
	}
}
.service__item_5 {
	grid-area: 2 / 2 / 3 / 3;
}
.service__item_6 {
	grid-area: 2 / 3 / 3 / 5;
}
.service__item_1 img,
.service__item_2 img,
.service__item_5 img,
.service__item_6 img {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.service__item-link_1 {
	bottom: 0;
	right: 0;
}

@media (max-width: 960px) {
	.service {
		margin-bottom: 100px;
	}
}

@media (max-width: 750px) {
	.service__list {
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(3, 283px);
	}

	.service__item_1 {
		grid-area: 1 / 1 / 1 / 1;
	}
	.service__item_2 {
		grid-area: 1 / 2 / 2 / 3;
	}
	.service__item_3 {
		grid-area: 2 / 1 / 3 / 2;
	}
	.service__item_4 {
		grid-area: 3 / 2 / 4 / 3;
	}
	.service__item_5 {
		grid-area: 3 / 1 / 4 / 2;
	}
	.service__item_6 {
		grid-area: 2 / 2 / 3 / 3;
	}
}

@media (max-width: 480px) {
	.service {
		margin-bottom: 80px;
	}
	.service__list {
		display: flex;
		flex-direction: column;
		row-gap: 16px;
	}
	.service__item_1 {
		height: 214px;
		order: 1;
	}
	.service__item_2 {
		height: 244px;
		width: 217px;
		order: 3;
	}
	.service__item_3 {
		padding-bottom: 8px;
		order: 2;
	}
	.service__item_4 {
		order: 4;
		padding-bottom: 8px;
	}
	.service__item_5 {
		height: 244px;
		width: 217px;
		order: 6;
	}
	.service__item_6 {
		height: 214px;
		order: 5;
	}
	.service__item-link_1 {
		bottom: -260px;
	}
	.service__item-link_2 {
		bottom: -490px;
	}
}
