html {
	scroll-behavior: smooth;
	overflow-x: hidden;
}

body {
	background-color: var(--page-bg);
	color: var(--dark);
	font-family: var(--font-main);
	font-size: 16px;
	overflow-x: hidden;
}

img {
	display: block;
}

html,
body,
.wrapper {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

.footer {
	margin-top: auto;
}

.title {
	margin-bottom: 70px;
	font-family: var(--font-family);
	font-weight: 700;
	font-size: 36px;
	color: #333;
	& span {
		color: var(--accent);
	}
}

@media (max-width: 960px) {
	.title {
		max-width: 305px;
		font-size: 36px;
	}
}

@media (max-width: 640px) {
	.title {
		max-width: 275px;
		margin-bottom: 40px;
		font-size: 24px;
	}
}

.title-deskr {
	position: absolute;
	right: 15px;
	top: 5px;
	max-width: 197px;
	color: var(--dark);
	font-size: 14px;
}

@media (max-width: 760px) {
	.title-deskr {
		left: 15px;
		top: 50px;
	}
}

.link-too {
	position: absolute;
	bottom: 0;
	right: 0;
	width: 44px;
	height: 44px;
	border: 1px solid #333;
	border-radius: 50%;
	background-color: transparent;
	transition:
		background-color 0.3s ease-in-out,
		border-color 0.3s ease-in-out,
		rotate 0.1s ease-in-out;
	&::after {
		content: "";
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: var(--accent);
		border-radius: 50%;
		scale: 0;
		transition: scale 0.3s ease-in-out;
	}
	&:hover {
		border-color: var(--accent);
		rotate: 45deg;
	}
	&:hover::after {
		scale: 1;
	}

	& svg {
		position: absolute;
		z-index: 2;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		stroke: #151515;
		transition: stroke 0.3s ease-in-out;
	}
	&:hover svg {
		stroke: #fff;
	}
}

// SOCIAL
.social {
	margin-top: 76px;
	display: flex;
	column-gap: 6px;
}
.social__link {
	position: relative;
	display: block;
	border-radius: 50%;
	width: 64px;
	height: 64px;
	background-color: transparent;
	border: 1px solid var(--accent);
	transition: background-color 0.3s ease-in-out;
	& svg {
		position: absolute;
		z-index: 5;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		fill: var(--accent);
		transition: fill 0.1s ease-in-out;
	}
	&::after {
		content: "";
		position: absolute;
		z-index: 4;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: var(--accent);
		scale: 0;
		transition: scale 0.3s ease-in-out;
	}
}

.social__link:hover {
	& svg {
		fill: #fff;
	}
	&::after {
		scale: 1;
	}
}
// SOCIAL

//MOOVE BTN
.move-btn{
	transition: transform 0.5s;
}



.cursor-follower {
	position: fixed;
	top: 0;
	left: 0;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	background-color: var(--accent);
	opacity: 0;
	z-index: 10000;
	user-select: none;
	pointer-events: none;
}
