.hero {
	margin-bottom: 120px;
}
.hero__wrap {
	position: relative;
}

.hero__info {
	position: absolute;
	z-index: 2;
}

.hero__social {
	margin-bottom: 20px;
}
.hero__name {
	width: 450px;
	height: 36px;
	margin-bottom: 14px;
}

.hero__name img {
	width: 450px;
	height: 36px;
	object-fit: cover;
}
.hero__title {
	display: none;
}
.hero__descr {
	max-width: 320px;
}

//
//
// slider
.hero__sliders {
	position: relative;
	height: 586px;
}

.swiper-thumbs {
	position: absolute;
	width: 212px;
	height: 263px;
	bottom: 0;
	right: 507px;
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.swiper-main {
	position: absolute;
	top: 0;
	right: 0;

	width: 494px;
	height: 586px;
	& img {
		width: 100%;
		height: 539px;
		object-fit: cover;
	}
}

.hero__sliders .swiper-button-next,
.hero__sliders .swiper-button-prev {
	position: absolute;
	top: calc(100% - 44px);
	left: 0;
	border: 1px solid var(--accent);
	border-radius: 50%;
	width: 64px;
	height: 64px;
	&::after {
		position: absolute;
		z-index: 2;
		font-size: 0;
		width: 5px;
		height: 10px;
		background-repeat: no-repeat;
		background-size: contain;
		transition: all 0.3s ease-in-out;
	}
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: var(--accent);
		scale: 0;
		transition: scale 0.3s ease-in-out;
	}
}

.hero__sliders .swiper-button-next::after {
	background-image: url(../img/svgicons/arrow-right.svg);
}
.hero__sliders .swiper-button-prev::after {
	background-image: url(../img/svgicons/arrow.svg);
}

.swiper-button-next:hover::before,
.swiper-button-prev:hover::before {
	scale: 1;
}
.swiper-button-next:hover::after {
	background-image: url(../img/svgicons/arrow-right-white.svg);
}

.swiper-button-prev:hover::after {
	background-image: url(../img/svgicons/arrow-white.svg);
}

.hero__sliders .swiper-button-next {
	left: 74px;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	opacity: 1;
	border: 1px solid var(--dark);
	background-color: transparent;
	background-repeat: no-repeat;
}

.swiper-scrollbar {
	background-color: #f4f4f4;
	opacity: 1;
}
.swiper-scrollbar-drag {
	background: var(--accent);
}

.swiper-horizontal > .swiper-scrollbar,
.swiper-scrollbar.swiper-scrollbar-horizontal {
	bottom: 0;
	left: 100%;
	transform: translateX(-100%);
	max-width: 494px;
}

.hero__katalog {
	position: absolute;
	z-index: 20;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	display: block;
	border-radius: 50%;
	width: 170px;
	height: 170px;
	color: #fff;
	background-color: var(--accent);
	line-height: 170px;
	text-align: center;
	transition: 0.3s;
}

@media (max-width: 1000px) {
	.hero__info {
		position: relative;
		margin-top: 35px;
		margin-bottom: 60px;
	}

	.hero__social {
		display: none;
	}

	.hero__sliders {
		height: 461px;
	}

	.swiper-thumbs {
		top: 0;
		left: 0;
		margin: 0;
		width: 38%;
		height: 323px;
	}

	.swiper-main {
		width: 494px;
		height: 461px;
		& img {
			width: 100%;
			height: 427px;
			object-fit: cover;
		}
	}

	.swiper-main {
		width: 60%;
	}

	.hero__katalog {
		top: 426px;
		left: 40%;
		transition: 0.5s;
	}

	.swiper-horizontal > .swiper-scrollbar,
	.swiper-scrollbar.swiper-scrollbar-horizontal {
		max-width: 590px;
	}
}

@media (max-width: 640px) {
	.hero {
		margin-bottom: 100px;
	}
	.hero__info {
		margin-bottom: 102px;
	}
	.hero__name {
		position: relative;
		width: 100%;
	}

	.swiper-button-next,
	.swiper-button-prev {
		display: none;
	}
	.swiper-scrollbar {
		width: 100% !important;
	}
	.hero__katalog {
		top: 235px;
	}
}

@media (max-width: 480px) {
	.hero {
		margin-bottom: 80px;
	}
	.hero__name {
		& img {
			object-fit: contain;
		}
	}
	.swiper-main {
		width: 68%;
	}

	.swiper-thumbs {
		width: calc(30% + 131px);
		left: -131px;
	}
}
