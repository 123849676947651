.reviwes {
	margin-bottom: 120px;
}
.reviwes__wrap {
	position: relative;
}
.reviwes__title {
	max-width: 100%;
}

.reviwes__slider {
	position: relative;
	padding-left: 250px;
	overflow: visible;
}

@media (min-width: 1030px) {
	.reviwes__slider {
		overflow: hidden;
	}
}

.swiper-reviwes {
	width: calc(100% + 250px);
}

.swiper-reviwes__slide {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 310px;
	padding: 30px;
	background: var(--grey);
	color: #5e5954;
}
.swiper-reviwes__name {
	font-weight: 700;
	font-size: 16px;
}

.swiper-reviwes__reviwes {
	max-width: 293px;
	font-weight: 400;
	font-size: 16px;
}

.swiper-reviwes__points {
	position: absolute;
	top: 30px;
	right: 30px;
}

.swiper-reviwes__link-too {
	position: absolute;
	bottom: 30px;
	right: 30px;
}

.swiper-reviwes__swiper-button-next,
.swiper-reviwes__swiper-button-prev {
	position: absolute;
	top: calc(100% - 44px);
	left: 0;
	border: 1px solid var(--accent);
	border-radius: 50%;
	width: 64px;
	height: 64px;
	&::after {
		content: "";
		position: absolute;
		z-index: 4;
		font-size: 0;
		width: 5px;
		height: 10px;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background-repeat: no-repeat;
		background-size: contain;
		transition: all 0.3s ease-in-out;
	}
	&::before {
		content: "";
		position: absolute;
		z-index: 1;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-color: var(--accent);
		scale: 0;
		transition: scale 0.3s ease-in-out;
	}
}
.swiper-reviwes__swiper-button-next::after {
	background-image: url(../img/svgicons/arrow-right.svg);
}
.swiper-reviwes__swiper-button-prev::after {
	background-image: url(../img/svgicons/arrow.svg);
}

.swiper-reviwes__swiper-button-next:hover::before,
.swiper-reviwes__swiper-button-prev:hover::before {
	scale: 1;
}

.swiper-reviwes__swiper-button-next:hover::after {
	background-image: url(../img/svgicons/arrow-right-white.svg);
}
.swiper-reviwes__swiper-button-prev:hover::after {
	background-image: url(../img/svgicons/arrow-white.svg);
}

.swiper-reviwes__swiper-button-next,
.swiper-reviwes__swiper-button-prev {
	top: 110px;
	left: 15px;
}

.swiper-reviwes__swiper-button-next {
	left: 85px;
}

.reviwes__btn {
	position: absolute;
	z-index: 50;
	bottom: 0;
	left: 16px;
	border: 1px solid var(--accent);
	border-radius: 118px;
	padding: 30px 45px;
	transition: color 0.3s ease-in-out;
	font-size: 14px;
	color: var(--accent);
	overflow: hidden;
	&:before {
		content: "";
		position: absolute;
		z-index: -1;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 118px;
		background-color: #fff;
		scale: 0;
		transition:
			scale 0.3s ease-in-out,
			background-color 0.1s ease-in-out;
	}
}

.reviwes__btn:hover {
	color: #fff;
	&:before {
		background-color: var(--accent);
		scale: 1;
	}
}

@media (max-width: 900px) {
	.swiper-reviwes {
		width: 100%;
	}

	.reviwes__slider {
		padding-left: 230px;
	}
	.swiper-reviwes__slide {
		padding: 30px 20px;
	}
}

@media (max-width: 690px) {
	.reviwes {
		margin-bottom: 100px;
	}
	.reviwes__title {
		margin-bottom: 84px;
	}
	.swiper-reviwes__reviwes {
		max-width: 260px;
	}
}

@media (max-width: 640px) {
	.reviwes {
		padding-bottom: 100px;
		margin-bottom: 0;
	}
	.reviwes__wrap {
		margin-bottom: 110px;
	}
	.reviwes__slider {
		padding-left: 0px;
	}
	.reviwes__btn {
		bottom: -110px;
		left: 50%;
		transform: translateX(-50%);
		min-width: 192px;
	}
	.swiper-reviwes__swiper-button-next,
	.swiper-reviwes__swiper-button-prev{
		display: none;
	}
}

@media (max-width: 480px) {
	.swiper-reviwes__slide {
		padding: 24px 18px;
	}

	.swiper-reviwes__points {
		top: 26px;
	}
	.swiper-reviwes__reviwes {
		max-width: calc(100% - 55px);
	}
}
