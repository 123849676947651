.faq {
	margin-bottom: 120px;
}

.faq__wrap {
	position: relative;
}

.faq__title {
	max-width: 100%;
}

.faq__title-deskr {
	max-width: 200px;
}

.faq__data {
	display: flex;
	column-gap: 16px;
}
.faq__data-foto {
	position: relative;
	flex: 0 2 50%;
	padding-top: 27px;
	height: 500px;

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
		background-position: center;
	}
}
.accordion {
	flex: 0 1 50%;
}

.accordion__item {
	border-bottom: 1px solid #e6e6e6;
	transition: border-color 3s ease-in-out;
	&:hover {
		border-color: var(--accent);
	}
}
.accordion__item:hover {
	.accordion__icon,
	.accordion__title {
		color: var(--accent);
	}
}

.accordion__btn {
	position: relative;
	display: block;
	text-align: left;
	width: 100%;
	background: none;
	outline: none;

	padding: 27px 0 22px;
	font-weight: 500;
	font-size: 16px;
	color: var(--dark);
}

.accordion__title {
	display: block;
	transition: color 0.3s ease-in-out;
	width: calc(100% - 60px);
}

.accordion__icon {
	display: inline-block;
	position: absolute;
	top: 18px;
	right: 0;
	width: 24px;
	height: 24px;
	color: var(--dark);
	transition:
		color 0.3s ease-in-out,
		rotate 0.3s ease-in-out;

	&::before {
		content: "";
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
		height: 1px;
		background: currentColor;
	}
	&::after {
		display: block;
		position: absolute;
		content: "";
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 1px;
		height: 100%;
		background: currentColor;
	}
}

.accordion__content {
	width: calc(100% - 60px);
}

.accordion {
	button[aria-expanded="true"] {
		color: var(--accent);
		.accordion__icon {
			rotate: 45deg;
			color: var(--accent);
		}
		+ .accordion__content {
			opacity: 1;
			max-height: 9em;
			transition: all 200ms linear;
			will-change: opacity, max-height;
		}
	}
	.accordion__content {
		opacity: 0;
		max-height: 0;
		overflow: hidden;
		transition:
			opacity 200ms linear,
			max-height 400ms linear;
		will-change: opacity, max-height;
		p {
			padding-bottom: 14px;
			font-weight: 400;
			font-size: 14px;
			line-height: 100%;
			color: var(--dark);
		}
	}
}
@media (max-width: 960px) {
	.faq__data {
		flex-direction: column;
	}
	.faq__data-foto {
		margin-top: 20px;
		position: relative;
		padding-bottom: 80%;
	}
	.accordion {
		flex: 0 1 50%;
	}
}

@media (max-width: 640px) {
	.faq {
		//  padding-bottom: 100px;
		margin-bottom: 100px;
	}
	.faq__data-foto {
		margin-top: 60px;
	}
}
