// .footer {
// }
.footer__wrap {
	position: relative;
}

.footer__title {
	max-width: 490px;
}
.footer__title-deskr {
	top: 78px;
}
.footer__tel-block {
	margin-bottom: 20px;
}
.footer__tel {
	display: block;
	font-size: 52px;
	line-height: 130%;
	color: var(--dark);
}

.footer__social {
	position: absolute;
	right: 15px;
	top: 0px;
	margin-top: 0;
}

.footer__map {
	position: relative;
}
.footer__katalog {
	top: 0;
	left: calc(100% - 97px);
	transition: 1s;
}

@media (max-width: 960px) {
	.footer__tel-block {
		margin: 56px 0 103px 0;
	}
	.footer__tel {
		font-size: 40px;
	}
	.footer__social {
		top: 180px;
	}
	.footer__title-deskr {
		top: 255px;
	}
	.footer__katalog {
		left: 50%;
	}
}

@media (max-width: 759px) {
	.footer__title-deskr {
		right: 20px;
		left: auto;
	}
}

@media (max-width: 660px) {
	.footer__tel-block {
		margin: 91px 0 204px 0;
	}

	.footer__tel {
		font-size: 32px;
	}

	.footer__title-deskr {
		right: auto;
		left: 15px;
		top: 105px;
	}

	.footer__social {
		left: 15px;
		top: 300px;
	}
}

@media (max-width: 640px) {
	.footer__title-deskr {
		top: 70px;
	}
	.footer__social {
		top: 260px;
	}
}
