:root {
	--container-width: 1030px;
	--container-padding: 15px;

	--font-main: "TildaSans", sans-serif;

	--page-bg: #fff;
	--dark: #333;
	--grey: #F4F4F4;
	--accent: #ff3e18;
}

@media (max-width: 640px) {
	:root {
		--container-padding: 10px;
	}
}
