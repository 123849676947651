.header__wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	height: 50px;
	margin-bottom: 26px;
	font-size: 14px;
	font-weight: 400;
}

.header__logo img {
	width: 183px;
	height: 36px;
	object-fit: contain;
}

.header__menu {
	padding-bottom: 5px;
}

.header__phone {
	padding-bottom: 5px;
	&_menu {
		display: none;
	}
}

// БУРГЕР
.nav__list {
	display: flex;
	align-items: center;
	column-gap: 20px;
}

.nav__link {
	position: relative;
	padding-left: 10px;
	color: var(--dark);
	transition: color 0.3s ease-in-out;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: transparent;
		transition: background-color 0.3s ease-in-out;
	}
}

.nav__link:hover {
	&::before {
		background-color: var(--accent);
	}
}

.header__phone {
	display: flex;
	column-gap: 16px;
}

.header__phone-link {
	position: relative;
	padding-left: 8px;
	color: var(--dark);
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 54%;
		transform: translateY(-50%);
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: var(--accent);
	}
}

.burger {
	position: relative;
	z-index: 101;
	width: 40px;
	height: 40px;
	display: none;
	border: none;
	border-radius: 50%;
	padding: 0;
	background-color: var(--accent);
	cursor: pointer;
	&::before,
	&::after {
		content: "";
		position: absolute;
		left: 50%;
		width: 24px;
		height: 2px;
		background-color: white;
		border-radius: 2px;
		transform: translateX(-50%);
		transition: all 0.3s ease-in-out;
	}
	&::before {
		top: 14px;
	}
	&::after {
		bottom: 12px;
	}
}

.burger__line {
	position: absolute;
	height: 2px;
	width: 24px;
	left: 50%;
	top: 50%;
	border-radius: 2px;
	transform: translate(-50%);
	background-color: white;
	transition: opacity 0.3s ease-in-out;
}

.burger--active .burger__line {
	opacity: 0;
}

.burger--active::before {
	left: 50%;
	top: 50%;
	transform: translate(-50%) rotate(45deg);
	
}

.burger--active::after {
	left: 50%;
	top: 50%;
	transform: translate(-50%) rotate(-45deg);
}

.stop--scroll {
	overflow: hidden;
}

.header__social {
	display: none;
}
@media (max-width: 961px) {
	.header__wrap {
		margin-top: 14px;
		margin-bottom: 0;
		height: 40px;
		align-items: center;
	}
	.header__logo {
		align-self: flex-start;
	}
	.header__logo img {
		width: 105px;
		height: 20px;
	}
	.nav {
		position: fixed;
		z-index: 100;
		left: 0;
		top: 0;
		width: 100%;
		height: 100vh;
		overflow-y: auto;
		padding: 90px 30px 30px 30px;
		background-color: #fff;
		transform: translateX(-100%);
		transition: transform 0.6s ease-in-out;
		&__list {
			flex-direction: column;
			align-items: flex-start;
			row-gap: 24px;
			margin-bottom: 57px;
		}
		&__link {
			font-size: 20px;
		}
	}
	.nav--visible {
		transform: none;
	}

	.burger {
		display: block;
	}

	.header__phone {
		display: none;
	}
	.header__phone_menu {
		display: block;
		font-weight: 400;
		font-size: 32px;
	}
	.header__phone-link_menu {
		display: block;
		color: var(--dark);
		&:last-child {
			margin-top: 22px;
		}
	}
	.header__social {
		display: flex;
	}
}
// БУРГЕР
.nav__item {
	overflow: hidden;
}
.nav__link {
	position: relative;
	color: #fff;
	& span {
		transition: all 0.5s ease-in-out;
		color: var(--dark);
		display: inline-block;
		position: absolute;
		left: 10px;

		width: 100%;
		height: 100%;
	}
}
.link-before {
	bottom: -30px;
	color: var(--accent) !important;
}
.link-after {
	bottom: 0;
	color: var(--dark) !important;
}

.nav__link:hover > .link-after {
	bottom: 30px;
	color: var(--dark);
	opacity: 1 !important;
}

.nav__link:hover > .link-before {
	bottom: 0px;
	color: var(--accent);
	opacity: 1 !important;
}
